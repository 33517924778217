import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { graphql, useStaticQuery } from "gatsby"

import "../../css/custom-css/custom-products-services.css"

import Header from "../../layouts/header4"
import Footer from "../../layouts/footer4"

import Layout from "../../components/custom-components/Layout"
import BannerHeader from "../../components/custom-components/BannerHeader"

import bg from "../../images/services/banner-service.png"
import Seo from "../../components/seo"

const CoTesting = () => {
  return (
    // <Layout>
    <div className="skin-1 index">
      <Seo title="Co-Testing" />

      <Header servicesPage />

      <BannerHeader background={bg} title="Co-Testing" servicesDetails />
      <div className="content-block">
        <div className="section-full content-inner-custom">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-4 col-md-5">
                <div>
                  <div className="icon-content">
                    <h2 className="dlab-title">Our Services</h2>
                  </div>
                </div>
                <div className="widget sidebar-widget ext-sidebar-menu widget_nav_menu">
                  <ul className="menu">
                    <li>
                      <Link to="/services/hpv-genotyping">
                        HPV DNA Genotyping Test
                      </Link>
                    </li>
                    <li>
                      <Link to="/services/hpv-highrisk">
                        HPV DNA High Risk Test
                      </Link>
                    </li>
                    <li>
                      <Link to="/services/liquid-based-cytology">
                        Liquid Based Cytology
                      </Link>
                    </li>
                    <li className="active">
                      <Link to="/services/co-testing">Co-Testing</Link>
                    </li>
                  </ul>
                </div>

                <div>
                  <div className="icon-content">
                    <h2 className="dlab-title">Our Products</h2>
                  </div>
                </div>
                <div className="widget sidebar-widget ext-sidebar-menu widget_nav_menu">
                  <ul className="menu">
                    <li>
                      <Link to="/products/hpv-xpress-matrix">
                        HPV XpressMatrix™ Kit
                      </Link>
                    </li>
                    <li>
                      <Link to="/products/sars-cov-2-real-time">
                        Elva Diagnostic SARS-CoV-2 Real-time RT PCR Kit
                      </Link>
                    </li>
                    <li>
                      <Link to="/products/sars-cov-2-saliva-nucleic">
                        Elva Diagnostic SARS-CoV-2 Saliva Nucleic Acid Test Kit
                      </Link>
                    </li>
                    <li>
                      <Link to="/products/ampfire-hpv-screening">
                        AmpFire HPV Screening HR 16/18 Kit
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-xl-9 col-lg-8 col-md-7 m-b30">
                <div className="row">
                  <div className="col-lg-12 mb-4">
                    <StaticImage src="../../images/services/service-cotesting.png" />
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <h4 className="dlab-title mt-2">Co-Testing</h4>
                    <p className="ml-4">
                      <ul>
                        <li>
                          Co-testing for cervical cancer using both cytology
                          test and human papillomavirus (HPV) test.
                        </li>
                        <li>
                          Improves sensitivity for CIN2+ in women 30 year of age
                          and older (less likely to miss an abnormality).
                        </li>
                        <li>Better management with referral colposcopy.</li>
                        <li>
                          Today co-testing in women ages 30 to 65 is the
                          preferred screening strategy recommended by most
                          medical societies, including the American Cancer
                          Society (ACS), American College of Obstetricians and
                          Gynecologists (AGOG), and American Society for
                          Colposcopy and Cervical Pathology (ASCCP), as well as
                          HOGI (Himpunan Onkologi Ginekologi Indonesia).
                        </li>
                      </ul>
                    </p>
                    <p className="text-justify font-weight-bold mb-2">
                      Reference Articles
                    </p>
                    <p className="ml-4">
                      <ol>
                        <li>
                          Walboomers JM et al. Human papillomavirus is a
                          necessary cause of invasive cervical cancer worldwide.
                          J Pathol. 1999 Sep; 189(1):12-9
                        </li>
                        <li>
                          Bosch FX et al. The causal relation between human
                          papillomavirus and cervical cancer. J clin Pathol.
                          2002 Apr; 55(4):244-65
                        </li>
                        <li>
                          Munoz N et al. Epidemiologic classification of human
                          papillomavirus types associated with cervical cancer.
                          N Engl J Med. 2003 Feb 6; 348(6):518-27
                        </li>
                        <li>
                          Lydia S. Murdiyarso et al. Single and multiple
                          high-risk and low-risk Human Papillomavirus
                          association with cervical lesions of 11,224 women in
                          Jakarta. Springer International, 2016
                        </li>
                      </ol>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer hideContactInfo />
      </div>
    </div>
  )
}

export default CoTesting
